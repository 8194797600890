var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("header-title", { attrs: { title: _vm.title } }),
      _c(
        "rs-form",
        {
          ref: "formData",
          staticClass: "form-70 commom-form",
          attrs: {
            model: _vm.formData,
            "show-message": false,
            "label-width": "156px",
            size: "small",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "rs-row",
            [
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "rs-form-item",
                    { attrs: { label: "组织名称", prop: "orgName" } },
                    [
                      _c("rs-input", {
                        attrs: { maxlength: 20, placeholder: "请输入" },
                        model: {
                          value: _vm.formData.orgName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "orgName", $$v)
                          },
                          expression: "formData.orgName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "rs-form-item",
                    { attrs: { label: "上级组织" } },
                    [
                      _c("org-select", {
                        attrs: {
                          organizationSysCode: _vm.formData.parentOrgSysCode,
                        },
                        on: { orgSelectChange: _vm.orgSelectChange },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "rs-row",
            [
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "rs-form-item",
                    { attrs: { label: "组织描述" } },
                    [
                      _c("rs-input", {
                        attrs: {
                          type: "textarea",
                          "show-word-limit": "",
                          maxlength: "200",
                          rows: 5,
                          placeholder: "请输入",
                        },
                        model: {
                          value: _vm.formData.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "remark", $$v)
                          },
                          expression: "formData.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "rs-form-item",
                    { attrs: { label: "选择状态" } },
                    [
                      _c(
                        "rs-select",
                        {
                          attrs: { placeholder: "选择状态" },
                          model: {
                            value: _vm.formData.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "status", $$v)
                            },
                            expression: "formData.status",
                          },
                        },
                        _vm._l(_vm.statusOptions, function (item) {
                          return _c("rs-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "edit-form button-box",
          staticStyle: { "text-align": "right" },
        },
        [
          _c(
            "rs-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.jumpBack(false)
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "rs-button",
            {
              attrs: {
                size: "small",
                type: "primary",
                loading: _vm.saveLoading,
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }